import Immutable from 'seamless-immutable';
import * as types from '../actions/ActionTypes';

const initialState = Immutable({
  queryParams: {},
});

export default function NavigationReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_QUERY_PARAMS:
      return Immutable.merge(state, { queryParams: action.queryParams });
    case types.CLEAR_QUERY_PARAMS:
      return Immutable.merge(state, { queryParams: {} });
    default:
      return state;
  }
}
