import { useSelector } from 'react-redux';
import { getCloudEventSessionId, getCompany, getCurrentOrder, getUser, getQueryParams } from '../../selectors';

const useCloudEventsBaseParams = () => {
  const company = useSelector(state => getCompany(state));
  const user = useSelector(state => getUser(state).user);
  const currentOrder = useSelector(state => getCurrentOrder(state));
  const sessionId = useSelector(state => getCloudEventSessionId(state));
  const queryParams = useSelector(state => getQueryParams(state));

  return {
    merchantId: company && company.id,
    userId: user && user.id,
    orderId: currentOrder && currentOrder.id,
    sessionId,
    queryParams,
  };
};

export default useCloudEventsBaseParams;
