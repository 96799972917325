import get from 'lodash/get';

export const getLoading = state => state.loading.loading;
export const getDialogLoading = state => state.loading.dialogLoading;
export const getUser = state => state.user; // current logged in user, null if not logged in
export const getUserToken = state => get(state, 'user.user.token', null);
export const getErrorMessage = state => state.message.errors;
export const getSuccessMessage = state => state.message.success;
export const getResources = state => state.resources;
export const getLocations = state => state.resources.locations;
export const getProducts = state => state.resources.products;
export const getCategories = state => state.resources.categories;
export const getCategoryById = (state, props) => (state.resources.categories
  .find(category => category.id.toString() === props.match.params.categoryId));
export const getCompany = state => get(state, 'resources.companies', null);
export const getProductById = (state, props) => (state.resources.products
  .find(product => product.id.toString() === props.match.params.productId));
export const getDialogProductById = (state, props) => (state.resources.products
  .find(product => product.id === props.productId));
export const getCurrentOrder = state => state.currentOrder.currentOrder;
export const getCurrentOrderItems = state => getCurrentOrder(state) && getCurrentOrder(state).items;
export const getUsers = state => getResources(state).users; // users resources with all the associations in the backend
export const getCurrentUserPaymentOptions = state => getUsers(state) && getUsers(state).paymentOptions;

export const getCurrentUserAddresses = state => get(state, 'resources.users.addresses', []);
export const getPortions = state => state.resources.portions;
export const getUnavailableCategoriesByDeliveryOption = state => get(state, 'resources.unavailableCategoriesByDeliveryOption', {});
export const getitemIdToWithProductNameMap = state => get(state, 'resources.itemIdToWithProductNameMap', {});
export const getCoupons = state => state.resources.coupons;
export const getSubscriptionsOffers = state => state.resources.offers;
export const getUserSubscriptions = state => state.resources.userSubscriptions;
export const getDollarRewardCoupons = state => getCoupons(state) && getCoupons(state).filter(coupon => coupon.rewardTiers && coupon.actionType === 'DOLLAR');
export const getIsOrderFlowDialogVisible = state => state.component.showOrderFlowDialog;
export const getIsCheckoutDrawerVisible = state => state.component.showCheckoutDrawer;
export const getIsPaymentDrawerVisible = state => state.component.showPaymentDrawer;
export const getIsProductDialogVisible = state => state.component.showProductDialog;
export const getTimeSlotSuggestions = state => state.currentOrder.timeSlotSuggestions;
export const getUserOrders = state => state.resources.users.orders;
export const getTimeSlot = state => state.currentOrder.timeSlot;
export const getNotifications = state => state.notifications.notifications;
export const getRemoteStyles = state => state.remoteStyles.styles;

export const getCloudEventSessionId = state => state.cloudEvents.sessionId;
export const getQueryParams = state => state.navigation.queryParams;
