// non api call actions
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const ERROR = 'ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_USER = 'SET_USER';
export const SET_REMEMBER_USER = 'SET_REMEMBER_USER';
export const SET_ERROR_SNACKBAR_MESSAGE = 'SET_ERROR_SNACKBAR_MESSAGE';

// api call actions - request
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST';
export const VERIFY_USER_REQUEST = 'VERIFY_USER_REQUEST';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const UPDATE_USER_REQUESTED = 'UPDATE_USER_REQUESTED';
export const SEND_VERIFICATION_TOKEN_REQUEST = 'SEND_VERIFICATION_TOKEN_REQUEST';
export const SEND_RESET_PASSWORD_TOKEN_REQUEST = 'SEND_RESET_PASSWORD_TOKEN_REQUEST';
export const GET_USER_REQUESTED = 'GET_USER_REQUESTED';
export const GET_ALL_RESOURCES_REQUESTED = 'GET_ALL_RESOURCES_REQUESTED';
export const GET_RESOURCE_REQUESTED = 'GET_RESOURCE_REQUESTED';
export const UPDATE_RESOURCE_REQUESTED = 'UPDATE_RESOURCE_REQUESTED';
export const ADD_RESOURCE_REQUESTED = 'ADD_RESOURCE_REQUESTED';
export const DELETE_RESOURCE_REQUESTED = 'DELETE_RESOURCE_REQUESTED';
export const CHECK_ADDRESS_REQUESTED = 'CHECK_ADDRESS_REQUESTED';
export const GET_OFFLINE_RESOURCE_SUCCESS = 'GET_OFFLINE_RESOURCE_SUCCESS';
export const GET_OFFLINE_RESOURCE_FAILURE = 'GET_OFFLINE_RESOURCE_FAILURE';
export const CHECK_DESIRED_TIME = 'CHECK_DESIRED_TIME';
export const GET_TIME_SUGGESTIONS = 'GET_TIME_SUGGESTIONS';
export const GET_USER_NOTIFICATIONS_REQUEST = 'GET_USER_NOTIFICATIONS_REQUEST';
export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST';

// api call actions - success
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const SEND_VERIFICATION_TOKEN_SUCCESS = 'SEND_VERIFICATION_TOKEN_SUCCESS';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const SEND_RESET_PASSWORD_TOKEN_SUCCESS = 'SEND_RESET_PASSWORD_TOKEN_SUCCESS';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_ALL_RESOURCES_SUCCESS = 'GET_ALL_RESOURCES_SUCCESS';
export const GET_RESOURCE_SUCCESS = 'GET_RESOURCE_SUCCESS';
export const UPDATE_RESOURCE_SUCCESS = 'UPDATE_RESOURCE_SUCCESS';
export const ADD_RESOURCE_SUCCESS = 'ADD_RESOURCE_SUCCESS';
export const DELETE_RESOURCE_SUCCESS = 'DELETE_RESOURCE_SUCCESS';
export const CREATE_ORDER_ITEM_SUCCESS = 'CREATE_ORDER_ITEM_SUCCESS';
export const DELETE_ORDER_ITEM_SUCCESS = 'DELETE_ORDER_ITEM_SUCCESS';
export const UPDATE_ORDER_ITEM_SUCCESS = 'UPDATE_ORDER_ITEM_SUCCESS';
export const CHECK_ADDRESS_SUCCESS = 'CHECK_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAILURE = 'ADD_ADDRESS_FAILURE';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_REQUESTED = 'ADD_ADDRESS_REQUESTED';
export const CHECK_DESIRED_TIME_SUCCESS = 'CHECK_DESIRED_TIME_SUCCESS';
export const GET_TIME_SUGGESTIONS_SUCCESS = 'GET_TIME_SUGGESTIONS_SUCCESS';
export const GET_USER_NOTIFICATIONS_SUCCESS = 'GET_USER_NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';

// api call actions - failure
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const VERIFY_USER_FAILURE = 'VERIFY_USER_FAILURE';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const SEND_VERIFICATION_TOKEN_FAILURE = 'SEND_VERIFICATION_TOKEN_FAILURE';
export const SEND_RESET_PASSWORD_TOKEN_FAILURE = 'SEND_RESET_PASSWORD_TOKEN_FAILURE';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_ALL_RESOURCES_FAILURE = 'GET_ALL_RESOURCES_FAILURE';
export const GET_RESOURCE_FAILURE = 'GET_RESOURCE_FAILURE';
export const UPDATE_RESOURCE_FAILURE = 'UPDATE_RESOURCE_FAILURE';
export const ADD_RESOURCE_FAILURE = 'ADD_RESOURCE_FAILURE';
export const DELETE_RESOURCE_FAILURE = 'DELETE_RESOURCE_FAILURE';
export const CHECK_DESIRED_TIME_FAILURE = 'CHECK_DESIRED_TIME_FAILURE';
export const GET_TIME_SUGGESTIONS_FAILURE = 'GET_TIME_SUGGESTIONS_FAILURE';
export const GET_USER_NOTIFICATIONS_FAILURE = 'GET_USER_NOTIFICATIONS_FAILURE';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';

// orders
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const MAKE_PAYMENT_SUCCESS = 'MAKE_PAYMENT_SUCCESS';

export const RESOURCES_FOR_INNER_FUNCTION_SUCCESS = 'RESOURCES_FOR_INNER_FUNCTION_SUCCESS';

// components
export const TOGGLE_COMPONENT_VISIBILITY = 'TOGGLE_COMPONENT_VISIBILITY';
export const HIDE_COMPONENT = 'HIDE_COMPONENT';
export const SHOW_COMPONENT = 'SHOW_COMPONENT';

// feature flags, translations, styles
export const SET_FEATURE_FLAGS_SUCCESS = 'SET_FEATURE_FLAGS_SUCCESS';
export const SET_IS_FETCHED_FEATURE_FLAGS_AVAILABLE = 'SET_IS_FETCHED_FEATURE_FLAGS_AVAILABLE';
export const SET_TRANSLATIONS_SUCCESS = 'SET_TRANSLATIONS_SUCCESS';
export const SET_IS_FETCHED_TRANSLATIONS_AVAILABLE = 'SET_IS_FETCHED_TRANSLATIONS_AVAILABLE';
export const SET_REMOTE_STYLES_SUCCESS = 'SET_REMOTE_STYLES_SUCCESS';
export const SET_IS_FETCHED_REMOTE_STYLES_AVAILABLE = 'SET_IS_FETCHED_REMOTE_STYLES_AVAILABLE';

// CloudEvents
export const SET_SESSION_ID = 'SET_SESSION_ID';

// Navigation
export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS';
export const CLEAR_QUERY_PARAMS = 'CLEAR_QUERY_PARAMS';
